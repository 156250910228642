// extracted by mini-css-extract-plugin
export var pinkLink = "WelcomeBanner-module--pink-link--iPit9";
export var imageBoxShared = "WelcomeBanner-module--image-box-shared--OKOAm";
export var textBoxShared = "WelcomeBanner-module--text-box-shared--qP5oq";
export var helloShared = "WelcomeBanner-module--hello-shared--vDGor";
export var signatureShared = "WelcomeBanner-module--signature-shared--uI60H";
export var imageBox = "WelcomeBanner-module--image-box--DxdMh";
export var textBox = "WelcomeBanner-module--text-box--dWFaL";
export var hello = "WelcomeBanner-module--hello--WVLZT";
export var text = "WelcomeBanner-module--text--H0pFO";
export var signature = "WelcomeBanner-module--signature--d+CaK";
export var bottomRow = "WelcomeBanner-module--bottom-row--O5bf9";
export var buttonBox = "WelcomeBanner-module--button-box--YZ7yR";